var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "portfolio-table"
  }, [_c('div', {
    staticClass: "portfolio-table__inner"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "portfolioTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(investedCompanyName)",
      fn: function fn(rowData) {
        return [_c('user-presentation', {
          staticClass: "table__user-presentation",
          attrs: {
            "name": rowData.item.investedCompanyName,
            "image": rowData.item.investedCompanyLogo
          }
        })];
      }
    }, {
      key: "cell(industry)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getIndustryLabel(rowData.item.industry)))])];
      }
    }, {
      key: "cell(stage)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getStageLabel(rowData.item.stage)))])];
      }
    }, {
      key: "cell(seriesOfSharesName)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(rowData.item.seriesOfSharesName ? rowData.item.seriesOfSharesName : 'N/A'))])];
      }
    }, {
      key: "cell(instrumentClass)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentClassLabel(rowData.item.instrumentClass)))])];
      }
    }, {
      key: "cell(instrumentType)",
      fn: function fn(rowData) {
        return [_c('span', [_vm._v(_vm._s(_vm.getInstrumentTypeLabel(rowData.item.instrumentType)))])];
      }
    }, {
      key: "cell(instrumentCount)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.instrumentCount, 0)) + " ")])])];
      }
    }, {
      key: "cell(nominalValue)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.nominalValue.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "text-right pr-3"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.total.value, 2)) + " ")])])];
      }
    }, {
      key: "cell(investment)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__arrow d-flex justify-content-end align-items-center pl-4 pr-4 text-right"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.investment.value, 2)) + " " + _vm._s(rowData.item.investment.currency) + " ")]), _c('div', {
          staticClass: "arrow",
          on: {
            "click": function click($event) {
              return _vm.openDetails(rowData);
            }
          }
        }, [_c('sygni-arrow-button', {
          attrs: {
            "open": rowData.item._showDetails,
            "rotate-on-click": true
          }
        })], 1)])];
      }
    }, {
      key: "row-details",
      fn: function fn(rowData) {
        return [_c('portfolio-inner-table', {
          attrs: {
            "items": rowData.item.details
          },
          on: {
            "openDetailsModal": _vm.openDetailsModal
          }
        })];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }